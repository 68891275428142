import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classname } from 'utils';
import { Heading } from 'elements';
import './ProjectTeamNew.css';

/** Список сотрудников, работавших над проектом */

class ProjectTeamNew extends Component {
  cl = classname('project-team-new');

  static propTypes = {
    team: PropTypes.arrayOf(PropTypes.object)
  };

  render() {
    const { team, data } = this.props;
    return (
      <div className={this.cl()}>
        <Heading level={6} as={'h2'}>
          Project Team
        </Heading>
        <div className={this.cl('items')}>
          {team.map((item, i) => {
            const { spec, list } = item;
            return (
              <div className={this.cl('item')} key={'itm-' + i}>
                <div className={this.cl('post')}>{spec}</div>
                <div className={this.cl('persons')}>
                  {list.map((person) => {
                    return (
                      <div className={this.cl('person')} key={'ptn-' + person}>
                        {person}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ProjectTeamNew;
