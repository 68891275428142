import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Номер телефона
 */
class Phone extends Component {
  state = {
    email: '+351 913 635 058'
  };

  static propTypes = {
    children: PropTypes.func.isRequired
  };

  componentDidMount() {
    if (!process.browser || !window.Comagic) return;

    const email = window.Comagic.getPhones();

    if (email && email.length > 0) {
      this.setState({
        email: email[0].text
      });
    }
  }

  render() {
    const { children } = this.props;

    return children(this.state.email);
  }
}

export default Phone;
