import React from 'react';

export const order = {
  title: 'Start your <br/>project now',
  descr: <>Расскажите о&nbsp;проекте и&nbsp;задайте вопросы&nbsp;&mdash; мы&nbsp;скоро ответим</>,
  contactsArr: [
    {
      type: 'email',
      value: 'order@nimax.agency'
    },
    // {
    //   type: 'phone',
    //   value: '+7 (812) 385-52-06'
    // },
    {
      type: 'link',
      value: 'telegramB',
      text: 'telegram',
      link: 'https://t.me/nikavitae',
      move: true
    },
    {
      type: 'link',
      value: 'attach',
      text: 'Brief',
      link: 'https://docs.google.com/document/d/1nhVq8UFaG03Re2xIT_looLFZGCctHiHVExyzFC_waeg/edit'
    }
  ],
  footerLinks: [
    // {
    //   text: 'Работа',
    //   link: 'https://hr.nimax.ru/',
    //   email: 'rabota@nimax.ru'
    // },
    // {
    //   text: 'Для сотрудничества',
    //   email: 'info@nimax.ru'
    // }
  ],
  account: (
    <>
      Наш аккаунт-директор
      <br /> отвечает в мессенджерах
    </>
  ),
  about: 'Your Project',
  contacts: 'Your Contacts',
  submit: 'Send',
  custom: {
    department: [
      { value: 'Комплексный', name: 'Complex' },
      { value: 'Брендинг', name: 'Branding' },
      { value: 'Веб', name: 'Web' },
      { value: 'Продвижение', name: 'Marketing' }
    ],
    budget: {
      full: [
        { value: '< 1 млн', name: '< 1 млн' },
        { value: '1–4 млн', name: '1–4 млн' },
        { value: '> 4 млн', name: '> 4 млн' }
      ],
      small: [
        { value: '1–2 млн', name: '1–2 млн' },
        { value: '2—5 млн', name: '2—5 млн' },
        {
          value: 'Более 5 млн',
          name: (
            <>
              <span>Более </span>5 млн
            </>
          )
        }
      ]
    },
    describe: [
      // 'Из&nbsp;какой вы&nbsp;компании, чем&nbsp;она&nbsp;занимается?',
      // 'С&nbsp;чем мы&nbsp;можем помочь? Как&nbsp;представляете результат?',
      // 'На&nbsp;какой срок работы и&nbsp;бюджет рассчитываете?',
      // 'Откуда вы&nbsp;о&nbsp;нас&nbsp;узнали?',
      // 'Напишите, если&nbsp;удобнее общаться в&nbsp;мессенджере.'
    ]
  },
  field: {
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    descr: 'Tell us more about the task',
    subscribe: (
      <>
        Я хочу получать предложения
        <br />и полезные статьи
        <br />
        от&nbsp;команды Nimax
      </>
    ),
    files: 'Add a file',
    agree: "Pressing the button I agree to <a href='/privacy-nimax-agency.pdf' target='_blank'>personal data processing</a>"
  },
  required: {
    name: 'Enter your name',
    phone: 'Enter your phone',
    email: 'Enter your email',
    descr: 'Describe the task'
    // append: <>Дайте согласие на&nbsp;обработку персональных данных</>
  },
  error: {
    phone: 'Enter the phone in&nbsp;the format <br/>+1 234 567-89-01 ',
    email: 'Enter the email in&nbsp;the format email@company.com'
  }
};

// export const jobs = {
//   title: (
//     <>
//       Оставьте <br />
//       заявку
//     </>
//   ),
//   descr: <>Расскажите о&nbsp;себе и&nbsp;задайте вопросы&nbsp;&mdash; мы скоро ответим.</>,
//   about: <>Сопроводительное письмо</>,
//   submit: <>Отправить</>,
//   field: {
//     name: 'Имя *',
//     phone: 'Телефон *',
//     email: 'Почта *',
//     descr: '',
//     subscribe: <>Я хочу получать полезные статьи от&nbsp;команды Nimax</>,
//     files: <>Прикрепите резюме и&nbsp;портфолио</>,
//     agree:
//       "Нажав на&nbsp;кнопку, соглашаюсь на&nbsp;обработку <a href='/privacy.pdf' target='_blank'>персональных данных</a>"
//   },
//   required: {
//     name: <>Введите имя</>,
//     phone: <>Введите телефон</>,
//     email: <>Введите эл.&nbsp;почту</>,
//     append: <>Дайте согласие на&nbsp;обработку персональных данных</>
//   },
//   error: {
//     phone: (
//       <>
//         Укажите телефон в&nbsp;формате <nobr>+7 812 385-52-06</nobr>
//       </>
//     ),
//     email: <>Укажите почту в&nbsp;формате nikita@nimax.ru</>
//   }
// };
