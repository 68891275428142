import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';

import { Icon, Heading, Phone, Email } from 'elements';

import './OrderSuccess.css';

/**
 * Статус формы
 */
class OrderSuccess extends Component {
  cl = classname('order-success');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('area')}>
          <div className={this.cl('window')}>
            <div className={this.cl('inner')}>
              <div className={this.cl('left')}>
                <div className={this.cl('left-inner')}>
                  <Heading level={1} className={this.cl('title')}>
                    Thank you for getting in touch, we’ll contact you within 2 business days
                  </Heading>
                  <div className={this.cl('caption')}>In the meantime take a look at our work.</div>
                </div>
              </div>
              <div className={this.cl('right')}>
                <div className={this.cl('process')}>
                  <h3>Our process:</h3>
                  <ul>
                    <li>Our manager will contact you as soon as possible to get the details</li>
                    <li>We’ll prepare a brief for you or arrange a meeting</li>
                    <li>We'll plan the project and finalize a proposal</li>
                    <li>We’ll agre on the start date and take care of the necessary documentation</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderSuccess;
