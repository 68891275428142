import React, { useEffect, useMemo } from 'react';
import { classname } from 'utils';
import Text from './components/Text';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { Image, Video } from 'elements';

import './media-banner.css';

export const REGEXP = /(\[[a-zA-Z]{1,}\])/;

const getAnnotation = (annotation = [], text = '') => {
  const annotationObj = {};
  const textArray = text.split(REGEXP);

  textArray.forEach((str) => {
    if (REGEXP.test(str)) {
      annotationObj[str] = annotation.shift();
    }
  });

  return {
    annotationObj,
    textArray
  };
};

const MediaBanner = ({ text, annotation, video, image }) => {
  const cl = classname('media_banner');
  const { width } = useWindowSize();
  const { textArray, annotationObj } = getAnnotation(annotation, text);

  return (
    <div className={cl()}>
      <div className={cl('inner')}>
        {width > 414 ? (
          <Video src={video} autoplay={true} loop={true} muted={true} />
        ) : (
          <Image src={image} className={cl('image')} width={412} height={620} />
        )}
      </div>
      {useMemo(() => {
        return <Text text={textArray} annotation={annotationObj} cl={cl} />;
      }, [])}
      <div className={cl('subtitle')}>Nimax Media</div>
    </div>
  );
};

export default MediaBanner;
