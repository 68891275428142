import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { classname, classnames } from 'utils';
import Icon from 'elements/Icon/Icon';
import './ToggleList.css';

class ToggleList extends Component {
  cl = classname('toggle-list');

  constructor(props){
    super(props);

    this.heightCurrent = '';
    
    this.state = { 
      heightMin: null,
      heightMax: null,
      isShow: false,
      minItems: 0
    };
      
  }

  componentDidMount() {
    this.setState({
      minItems: this.props.minItems,
      heightMin: window.getComputedStyle(document.documentElement.querySelector('.toggle-list'), null).minHeight,
      heightMax: window.getComputedStyle(document.documentElement.querySelector('.toggle-list'), null).maxHeight
    })
  }

  componentDidUpdate() {
    if(this.state.isShow) {
      const tagsItems = document.documentElement.querySelectorAll('.toggle-list__item');
      [].forEach.call(tagsItems, (item, index) => { 
        if(index >= this.props.minItems) {
          item.classList.add('toggle-list__item_animated')
        }
      })
    }
  }

  toggleList() {
    const isShow = !this.state.isShow;
    const minItems = !isShow ? this.props.minItems : this.props.items.length
    this.setState({
      isShow: isShow,
      minItems: minItems
    })
    this.heightCurrent = !isShow ? this.state.heightMin : this.state.heightMax
  }

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /**Список элементов */
    items: PropTypes.arrayOf(PropTypes.node),
    /**Минимальное количество открытых элементов списка */
    minItems: PropTypes.number,
    /**Подпись в кнопке */
    buttonLabel: PropTypes.string
  };

  render() {
    const {className, items, minItems, buttonLabel} = this.props;

    return (
      <div className={classnames(this.cl(), className)} style={{height: this.heightCurrent}}>
        <div className={this.cl('list')}>
          {items.map((item, index) => {
            if (this.state.minItems > 0 && index >= this.state.minItems) {
              return <div className={this.cl('item', { 'item-hide': true })}>{item}</div>;
            } else {
              return <div className={this.cl('item')}>{item}</div>
            }  
          })}
          {minItems && 
            <button className={this.cl('button', {open: this.state.isShow})} onClick={this.toggleList.bind(this)} >
              {buttonLabel}
              <Icon name={'arrow'} className={this.cl('arrow')} />
            </button>
          }
          
        </div>
      </div>
    );
  }
}

export default ToggleList;