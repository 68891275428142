export default [
  {
    name: 'Portfolio',
    link: '/projects/'
  },
  {
    name: 'Branding',
    link: '/brands/'
  },
  {
    name: 'UX/UI',
    link: '/ux-ui/'
  },
  {
    name: 'Web',
    link: '/soft/'
  },
  {
    name: 'Performance',
    link: '/performance/'
  },
  {
    name: 'Communications',
    link: '/communications/'
  },
  {
    name: 'Contacts',
    link: '/contacts/'
  }
];
