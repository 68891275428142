import React, { Component } from 'react';

import Icon from 'elements/Icon/Icon';
import { classnames, classname } from 'utils';

import './Footer.css';

export class Footer extends Component {
  cl = classname('footer');

  render() {
    const { className } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <header className={classes}>
        <div className={this.cl('nav')}>
          <a href="/projects/">Portfolio</a>
          <a href="/brands/">Branding</a>
          <a href="/ux-ui/">UX/UI</a>
          <a href="/soft/">Web</a>
          <a href="/performance/">Performance</a>
          <a href="/communications/">Communications</a>
          <a href="/contacts/">Contacts</a>
        </div>
        <div className={this.cl('social')}>
          <a href="https://www.behance.net/nimaxbrands" target="_blank" rel="noreferrer noopener">
            <Icon name={'behance'} />
          </a>
          <a href="https://dribbble.com/Nimax" target="_blank" rel="noreferrer noopener">
            <Icon name={'dribbble'} />
          </a>
        </div>
      </header>
    );
  }
}
