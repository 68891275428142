import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { FaceList } from 'components';
import { Heading, Icon } from 'elements';
import { classname, classnames } from 'utils';
import './Comment.css';

/**
 * Отзыв
 */
class Comment extends Component {
  cl = classname('comment');

  state = {
    isShow: false
  };

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Содержимое комментария */
    children: PropTypes.node.isRequired,
    /** Авторы */
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    /** Заголовок */
    title: PropTypes.string,
    /** Инлайн подпись */
    inline: PropTypes.bool
  };

  onClick(e) {
    this.setState({
      isShow: !this.state.isShow
    });

    e.preventDefault();
  }

  render() {
    const { className, authors, children, title, inline } = this.props;
    const { isShow } = this.state;

    const isLarge = React.Children.count(children) > 2;

    return (
      <div className={classnames(this.cl(), className)}>
        {title && (
          <Heading level={5} as={'h2'} className={this.cl('title')}>
            {title}
          </Heading>
        )}
        <div className={this.cl('container')}>
          <div className={this.cl(inline ? 'wrapper-inline' : 'wrapper')}>
            <div className={this.cl('inner')}>
              {isLarge ? (
                <>
                  {React.Children.map(children, (item, index) => (isShow || index < 2 ? item : null))}
                  {!isShow && (
                    <button type={'button'} className={this.cl('button')} onClick={(e) => this.onClick(e)}>
                      see more
                      <div className={this.cl('icon')}>
                        <Icon name={'arrow'} />
                      </div>
                    </button>
                  )}
                </>
              ) : (
                children
              )}
            </div>
            <div className={this.cl('authors')}>
              <FaceList size={'l'}>{authors}</FaceList>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Comment;
