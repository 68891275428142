import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'elements';
import { classnames, classname, raw } from 'utils';

import './ContentAbout.css';

export class ContentAbout extends PureComponent {
  cl = classname('content-about');

  static propTypes = {
    /** Наличие футера */
    footer: PropTypes.bool,
    /** Сноска */
    footnote: PropTypes.node
  };

  static defaultProps = {
    footer: false
  };

  render() {
    const { className, footer, children, footnote } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('content')}>{children}</div>
        {footnote && <div className={this.cl('footnote')}>{footnote}</div>}
        {footer && (
          <div className={this.cl('footer')}>
            <div className={this.cl('contacts')}>
              <a href="mailto:hello@nimax.ru" className={this.cl('mail')}>
                hello@nimax.ru
              </a>
              <a href="tel:+78126042066" className={this.cl('phone')}>
                +7 (812) 604-20-66
              </a>
            </div>
            <div className={this.cl('action')}>
              <Button url={'/order/'}>Request</Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
