import React, { Component } from 'react';
import { classname } from 'utils';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Face } from 'elements';
import './FaceList.css';

/**
 * Список людей
 */
class FaceList extends Component {
  static propTypes = {
    /** Поток вывода */
    flow: PropTypes.oneOf(['column', 'row']),
    /** Поток вывода */
    type: PropTypes.oneOf(['default', 'short', 'hidden']),
    /** Стиль */
    theme: PropTypes.oneOf(['dark', 'light']),
    /** Размер */
    size: Face.propTypes.size,
    /** Данные */
    children: PropTypes.arrayOf({
      title: PropTypes.string.isRequired,
      post: PropTypes.string.isRequired,
      image: PropTypes.string
    }).isRequired
  };

  static defaultProps = {
    flow: 'column',
    theme: 'dark',
    type: 'default'
  };

  cl = classname('face-list');

  render() {
    const { flow, children, type, size, data } = this.props;

    return (
      <div className={this.cl({ flow, type })}>
        {children &&
          children.map(id => {
            if (!data[id]) return null;

            return (
              <div className={this.cl('item')} key={id}>
                <Face type={ (children.length > 1) ? type : 'default'} size={size} {...data[id]} />
              </div>
            );
          })}
      </div>
    );
  }
}

export default props =>
  process.docz ? (
    <FaceList
      data={{
        'ogorodnikova-elena': {
          title: 'Елена Огородникова',
          post: 'Руководитель Nimax Change',
          image: '/peoples/ogorodnikova-elena/avatar.jpg'
        }
      }}
      {...props}
    />
  ) : (
    <StaticQuery
      query={graphql`
        {
          data: allMdx(filter: { fields: { id: { regex: "^/peoples//" } } }) {
            nodes {
              fields {
                slug
              }
              frontmatter {
                title
                post
                image
              }
            }
          }
        }
      `}
      render={({ data: { nodes: peoples } }) => {
        peoples = peoples.reduce((list, item) => ({ ...list, [item.fields.slug]: item.frontmatter }), {});

        return <FaceList data={peoples} {...props} />;
      }}
    />
  );
