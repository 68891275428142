import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { AboutContacts } from './components';
import { Icon } from 'elements';
import { GetServices, GetNav, GetSocial } from 'query';
import { classnames, classname, raw } from 'utils';

import './About.css';

class About extends Component {
  cl = classname('about');

  static propTypes = {
    className: PropTypes.string
  };

  render() {
    const { className } = this.props;

    const classes = classnames(className, this.cl());

    return (
      <div className={classes}>
        <div className={this.cl('line', { align: 'top' })}>
          <div className={this.cl('container', { align: 'left' })}>
            <div className="about__services">
              <div className={this.cl('heading')}>Services</div>
              <div className={this.cl('list')}>
                <GetServices>
                  {(data) =>
                    data.map(({ title, url }) => {
                      const linkProps = { className: this.cl('link'), key: title };
                      const internal = /https?:\/\//.test(url);
                      const Tag = url ? (!internal ? Link : 'a') : 'div';
                      if (!internal) {
                        linkProps.to = url;
                      } else {
                        linkProps.href = url;
                        linkProps.target = '_blank';
                      }

                      return <Tag to={url} key={title} {...linkProps} {...raw(title)} />;
                    })
                  }
                </GetServices>
              </div>
            </div>
          </div>
          <div className={this.cl('container', { align: 'right' })}>
            <div className={this.cl('heading')}>Agency</div>
            <div className={this.cl('list', { nav: true })}>
              <GetNav>
                {(data) =>
                  data.map(({ title, url }) =>
                    url[0] === '/' ? (
                      <Link to={url} className={this.cl('link')} key={title} {...raw(title)} />
                    ) : (
                      <a href={url} target={'_blank'} className={this.cl('link')} key={title} {...raw(title)} />
                    )
                  )
                }
              </GetNav>
            </div>
          </div>
        </div>
        <div className={this.cl('line', { align: 'bottom' })}>
          <div className={this.cl('container', { align: 'left', type: 'contacts' })}>
            <div className={this.cl('descr')}>We help companies benefit from digital technologies</div>
          </div>
          <div className={this.cl('container', { align: 'right' })}>
            <div className={this.cl('append')}>
              <AboutContacts />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
