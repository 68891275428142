import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';

/**
 * SEO
 */
class Seo extends PureComponent {
  render() {
    const { title, keywords, description, url, og, schema } = this.props;

    const fullTitle =
      (title
        ? title
            .replace(/&nbsp;/g, ' ')
            .replace(/&mdash;/g, '—')
            .replace(/<[a-z]{1,10}>/g, '')
            .replace(/&#8209;/g, '-') + ' — '
        : '') + 'Digital-agency Nimax';

    const metaDescription = description
      ? description
          .replace(/&nbsp;/g, ' ')
          .replace(/&mdash;/g, '—')
          .replace(/<[a-z]{1,10}>/g, '')
      : '';

    const ogImage = og ? 'https://static.nimax.ru' + og : '/og/nimax_snippet.png';

    return (
      <Helmet>
        <title>{fullTitle}</title>

        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={keywords} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="og:site_name" content="nimax.agency" />

        <meta property="twitter:site" content="" />
        <meta property="og:title" content={fullTitle} />
        <meta property="twitter:title" content={fullTitle} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={metaDescription} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="og:url" content={url} />
        <meta property="twitter:url" content={url} />
        <meta property="article:author" content="https://www.facebook.com/nimax.ru/" />
        <meta property="article:publisher" content="https://www.facebook.com/nimax.ru/" />
        <meta property="og:image" content={ogImage} />
        <meta property="og:image:width" content="1145" />
        <meta property="og:image:height" content="599" />

        {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
      </Helmet>
    );
  }
}

export default Seo;
