import React from 'react';
import getImageUrl from '../utils/getImageUrl';
import showIndex from '../utils/showIndex';

import arrow from './arrow.svg';
import arrowSmall from './shape.svg';

const Item = ({ data, cl }) => {
  const { index, link, image, text, description, label } = data;
  return (
    <div className={cl('item-wrapper')}>
      <div className={cl('item-content')}>
        <div>
          <div className={cl('item-label')} dangerouslySetInnerHTML={{ __html: showIndex(index) }} />
          <div className={cl('item-text-wrapper')}>
            <div className={cl('item-text')} dangerouslySetInnerHTML={{ __html: text }} />
            <div className={cl('item-arrow')}>
              <img src={arrow} className={cl('item-arrow-img')} />
            </div>
          </div>
        </div>
        <div>
          <a href={link} className={cl('item-right-wrapper')}>
            <div>
              <div className={cl('item-label')} dangerouslySetInnerHTML={{ __html: label }} />
              <div className={cl('item-text')} dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            {link && <div className={cl('item-button')}>Подробнее</div>}
          </a>
        </div>
      </div>
      <div className={cl('item-bg')} style={{ backgroundImage: `url(${getImageUrl(image)})` }} />
    </div>
  );
};

export default Item;
