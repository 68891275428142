import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname, raw, scroll, copyToClipboard } from 'utils';
import { Icon } from 'elements';
import './Heading.css';

/**
 * Заголовок
 */
class Heading extends Component {
  cl = classname('heading');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Размер */
    level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    /** Тег */
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /** Сделать ли якорем */
    anchor: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  };

  static defaultProps = {
    level: 1,
    as: 'div'
  };

  state = {
    anchor: false,
    copy: false
  };

  componentDidMount() {
    if (!process.browser || !this.props.anchor || !window.location.hash) return;

    const hash = decodeURI(window.location.hash.substr(1));
    const id = this.getId();

    this.setState({
      anchor: hash === id
    });

    window.addEventListener('replaceState', () => {
      const hash = decodeURI(window.location.hash.substr(1));
      const id = this.getId();

      this.setState({
        anchor: hash === id
      });
    });
  }

  /**
   * Получаю id
   * @returns {string}
   */
  getId() {
    const { anchor, children } = this.props;

    let id = typeof anchor === 'string' ? anchor : children;

    if (typeof id !== 'string') id = id.join('');

    return id
      .toLowerCase()
      .replace(/[^a-zа-я0-9]+/g, '_')
      .replace(/__/, '_');
  }

  onClick(e) {
    const id = this.getId();
    const link = window.location.pathname + '#' + id;

    window.history.replaceState(null, document.title, link);

    copyToClipboard(window.location.origin + link);

    this.setState({
      copy: true
    });

    setTimeout(() => {
      this.setState({
        copy: false
      });
    }, 2000);

    const anchor = document.querySelector(decodeURI('#' + id));

    if (anchor) {
      document.scrollingElement.scrollTop = anchor.offset().top;
    }

    e.preventDefault();
  }

  render() {
    const { className, level, anchor, children, as: Tag } = this.props;

    const classes = classnames(className, this.cl({ level, ...this.state }));

    return anchor ? (
      <div className={classes}>
        <div className={this.cl('id')} id={this.getId()} />
        <div className={this.cl('anchor')}>
          <a href={'#' + this.getId()} onClick={e => this.onClick(e)} className={this.cl('link')}>
            <Icon name="anchor" size="fit" />
          </a>
        </div>
        <Tag className={this.cl('inner')} {...raw(children)} />
        <div className={this.cl('copy')}>Copied</div>
      </div>
    ) : (
      <Tag className={classes} {...raw(children)} />
    );
  }
}

export default Heading;
