import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { classname, classnames } from 'utils';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import {
  Figure,
  Footnotes,
  Info,
  Columns,
  Graph,
  Price,
  Ticker,
  Comment,
  Dropdown,
  Background,
  ShareDownload,
  Nda,
  RatingList,
  Feed,
  // FaceList,
  Also,
  Gallery,
  InfoList,
  Presentation,
  ListLogos,
  Download,
  CustomBuildSwiper,
  ChangeWord,
  Table,
  Toggle,
  Quote,
  SliderCustom,
  Increase,
  DownloadMedia,
  ProjectTeamNew
} from 'components';
import {
  Heading,
  Image,
  Prolog,
  Face,
  Factoid,
  Digits,
  Video,
  General,
  Paragraph,
  List,
  Rating,
  Note,
  Motive,
  Button,
  Invite,
  Recording,
  Match
} from 'elements';

import './Article.css';

/**
 * Статья
 */
class Article extends Component {
  cl = classname('article');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string
  };

  render() {
    const { className, children } = this.props;

    const classes = classnames(this.cl(), className);

    return (
      <div className={classes}>
        <div className={this.cl('wrapper')}>
          <MDXProvider
            components={{
              h1: ({ children, level }) => (
                <Heading level={4} as={'h2'} className={this.cl('heading', { level: level })} anchor={true}>
                  {children}
                </Heading>
              ),
              h2: ({ children, level }) => (
                <Heading level={5} as={'h3'} className={this.cl('heading', { level: level })} anchor={true}>
                  {children}
                </Heading>
              ),
              h3: ({ children, level }) => (
                <Heading level={6} as={'h4'} className={this.cl('heading', { level: level })} anchor={true}>
                  {children}
                </Heading>
              ),
              img: ({ src, alt, title }) => (
                <Figure className={this.cl('figure')} image={src} caption={alt} gap={title} />
              ),
              p: ({ children }) => <Paragraph className={this.cl('paragraph')}>{children}</Paragraph>,
              ul: ({ children }) => (
                <List type={'bullet'} className={this.cl('list')}>
                  {children}
                </List>
              ),
              ol: ({ children }) => (
                <List type={'number'} className={this.cl('list')}>
                  {children}
                </List>
              ),
              li: (props) => <li className={'list__item'} {...props} />,
              lh: (props) => <lh className={'list__heading'} {...props} />,
              Background: ({ className, ...props }) => (
                <Background className={classnames(this.cl('backgroud'), className)} {...props} />
              ),
              Figure: ({ className, ...props }) => (
                <Figure className={classnames(this.cl('figure'), className)} {...props} />
              ),
              Presentation: ({ ...props }) => <Presentation className={this.cl('presentation')} {...props} />,
              Footnotes,
              Info,
              Heading: (props) => {
                return (
                  <Heading className={this.cl('heading', { level: props.level })} anchor={props.level < 5} {...props} />
                );
              },
              Image,
              Prolog,
              Fragment,
              Face,
              // FaceList,
              Also,
              Factoid: (props) => <Factoid className={'article__factoid'} {...props} />,
              Digits,
              Graph,
              Columns,
              Video,
              Button,
              Note,
              Motive,
              Invite,
              Recording,
              Match,
              ListLogos,
              Table,
              Toggle,
              Download,
              CustomBuildSwiper,
              ChangeWord,
              Price: (props) => <Price className={this.cl('price')} {...props} />,
              Ticker,
              Quote,
              SliderCustom,
              General: (props) => <General className={this.cl('general')} {...props} />,
              Comment: (props) => <Comment className={'article__comment'} {...props} />,
              Dropdown: (props) => <Dropdown {...props} />,
              ShareDownload,
              Nda: (props) => <Nda className={this.cl('nda')} {...props} />,
              Rating: (props) => <Rating className={this.cl('rating')} {...props} />,
              RatingList: (props) => <RatingList className={this.cl('rating-list')} {...props} />,
              Feed: (props) => <Feed className={this.cl('feed')} columns={2} {...props} />,
              Gallery: (props) => <Gallery className={this.cl('gallery')} {...props} />,
              InfoList: (props) => <InfoList className={this.cl('info-list')} {...props} />,
              Increase,
              DownloadMedia,
              ProjectTeamNew
            }}
          >
            <MDXRenderer>{children}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    );
  }
}

export default Article;
