import React, { Component } from 'react';

import { Email, Phone, Button, Heading, Address } from 'elements';
import { ListTags } from 'components';
import { classnames, classname, raw } from 'utils';

import './Hat.css';

export class Hat extends Component {
  cl = classname('hat');

  render() {
    const { className, title, as, office } = this.props;

    const classes = classnames(className, this.cl());
    return (
      <div className={classes}>
        <div className={this.cl('wrapper')}>
          <Heading as={as} className={this.cl('title')}>
            {title}
          </Heading>
          <div className={this.cl('links')}>
            <Email>
              {(email) => (
                <a href={`mailto:${email}`} className={this.cl('email')}>
                  {email}
                </a>
              )}
            </Email>
            {/*<Phone>{(phone) => <a href={'tel:' + phone}>{phone}</a>}</Phone>*/}
            <br />
            {office && (
              <Address>
                Lisbon <br />
                <br />
                2750-834 Cascais, Estrada Malveira da Serra 920 Aldeia do&nbsp;Juso, Edificio LACS
              </Address>
            )}
          </div>
        </div>
      </div>
    );
  }
}
