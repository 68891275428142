import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { classnames, classname } from 'utils';
import './CookiesPopup.css';
import { ESSENTIAL_COOKIES } from '../../utils/constants';

const CookiesPopup = ({ className }) => {
  const cl = classname('cookies-popup');
  const classes = classnames(className, cl());

  const acceptedCookiesBefore =
    Cookies.get(ESSENTIAL_COOKIES.COOKIE_POLICY_ACCEPTED_ALL) ||
    Cookies.get(ESSENTIAL_COOKIES.COOKIE_POLICY_ACCEPTED_ESSENTIAL);

  const [isCookiesAccepted, setIsCookiesAccepted] = useState(acceptedCookiesBefore);

  const acceptAllCookies = () => {
    Cookies.set(ESSENTIAL_COOKIES.COOKIE_POLICY_ACCEPTED_ALL, '1');
    Cookies.remove(ESSENTIAL_COOKIES.COOKIE_POLICY_ACCEPTED_ESSENTIAL);
    setIsCookiesAccepted(true);
  };

  const acceptEssentialCookies = () => {
    Cookies.set(ESSENTIAL_COOKIES.COOKIE_POLICY_ACCEPTED_ESSENTIAL, '1');
    Cookies.remove(ESSENTIAL_COOKIES.COOKIE_POLICY_ACCEPTED_ALL);
    setIsCookiesAccepted(true);

    // we have to reload page in order to disable optional third-party analytics
    window.location.reload();
  };

  if (typeof window === 'undefined') return null;

  if (isCookiesAccepted) return null;

  return (
    <div className={classes}>
      <div>
        This website is using{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://drive.google.com/file/d/1qdIfYbJI-YVYgOoOQhTQiBgH1eqFRsI_/view?usp=sharing"
        >
          cookies
        </a>
        .
      </div>

      <div className={cl('controls')}>
        <div onClick={acceptAllCookies} className={cl('btn')}>
          ACCEPT ALL
        </div>

        <div onClick={acceptEssentialCookies} className={cl('btn')}>
          ACCEPT ESSENTIAL
        </div>
      </div>
    </div>
  );
};

export default CookiesPopup;
