export const INDUSTRY_CATEGORIES = {
  FINTECH: 'fintech',
  IT: 'it'
};

export const ESSENTIAL_COOKIES = {
  COOKIE_POLICY_ACCEPTED_ALL: 'cookie-acc-all',
  // This one may be used by the direct value. Be careful while changing this field
  COOKIE_POLICY_ACCEPTED_ESSENTIAL: 'cookie-acc-ess'
};
