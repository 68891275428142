import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { navigate } from '@reach/router';

import { FormStatus, Error } from 'components';
import { Input, Heading, Button, Files, Loader, Phone, Email, Radio, Icon, Image } from 'elements';
import { classnames, classname, back, testEmail, testPhone } from 'utils';

import * as tokens from './Order.tokens';
import './Order.css';
import Contact from './components/Contact';
import FooterLinks from './components/FooterLinks';
import Select from '../../elements/Select/Select';

/**
 * Заявка
 */
class Order extends Component {
  cl = classname('order');

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Заловок */
    title: PropTypes.string,
    /** Размер заголовка */
    level: PropTypes.number,
    /** На весь экран */
    fullscreen: PropTypes.bool,
    budget: PropTypes.oneOf(['small', 'full'])
  };

  static defaultProps = {
    level: 1,
    action: 'order',
    video: ['/hello/hello.h264.mp4'],
    image: '/hello/order-background.png'
  };

  state = {
    name: '',
    phone: '',
    descr: '',
    account: '',
    department: '',
    budget: '',
    files: [],
    agree: true,
    subscribe: false,
    sending: false,
    success: false,
    error: {},
    focus: false,
    where: ''
  };

  componentDidMount() {
    if (process.browser && this.video) {
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.play();
        } else {
          this.video.pause();
        }
      });

      this.observer.observe(this.video);
    }
  }

  componentWillUnmount() {
    if (process.browser && this.video) {
      this.observer.disconnect();
    }
  }

  play() {
    this.video.play().catch((err) =>
      setTimeout(() => {
        if (!this.video) return;

        this.play();
      }, 1000)
    );
  }

  /**
   * Событие изменения значения
   * @param name
   * @param value
   * @param callback
   */
  onChange(name, value, callback = () => {}) {
    this.setState(
      {
        [name]: value
      },
      () => callback()
    );

    switch (name) {
      case 'name':
        if (value.length > 2 && this.state.error.name) {
          this.setState({
            error: {
              ...this.state.error,
              name: null
            }
          });
        }
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'interactionFormOrder',
            eventCategory: 'new_formOrder',
            eventAction: 'interactionWith-formOrder'
          });
        }
        break;
      case 'email':
        if (testEmail(value) && this.state.error.email) {
          this.setState({
            error: {
              ...this.state.error,
              email: null
            }
          });
        }
        break;
      case 'phone':
        if (testPhone(value) && this.state.error.phone) {
          this.setState({
            error: {
              ...this.state.error,
              phone: null
            }
          });
        }
        break;
      case 'descr':
        if (this.state.error.descr) {
          this.setState({
            error: {
              ...this.state.error,
              descr: null
            }
          });
        }
        break;
    }
  }

  /**
   * Событие изменения файлов
   * @param files
   */
  onFilesChange(files) {
    this.setState({
      files
    });
  }

  /**
   * Событие ошибки при загрузке файлов
   * @param error
   * @param file
   */
  onFilesError(error, file) {
    console.log('error code ' + error.code + ': ' + error.message);
  }

  /**
   * Удаление файла
   * @param file
   */
  filesRemoveOne(file) {
    this.files.removeFile(file);
  }

  /**
   * Присвоение ссылки у компонента файла
   * @param node
   */
  setRef(node) {
    this.files = node;
  }

  /**
   * Событие по клику по клику для повышения области фокуса
   * @param target
   */
  onClickForm({ target }) {
    if (!target.closest('button') && !target.closest('a') && !target.closest('.' + this.cl('error'))) {
      const section = target.closest('.' + this.cl('section'));

      if (!section) return;

      const input = section.querySelector('.input__field');

      if (input) {
        if (input.type === 'file') {
          input.click();
        } else {
          input.focus();
        }
      }
    }
  }

  /**
   * Событие при отправке формы
   * @param event
   */
  onSubmit(event) {
    event.preventDefault();

    if (this.isValid()) {
      this.sendOrder();
    }
  }

  /**
   * Отправка заявки
   */
  sendOrder() {
    const { fullscreen, action } = this.props;
    const state = this.state;

    this.setState({
      sending: true
    });

    const url = 'https://api.nimax.ru/' + action + '.php';
    const data = new FormData(this.block);
    const method = 'post';
    const headers = {
      'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'XMLHttpRequest'
    };

    Object.keys(this.state.files).forEach((key) => {
      const file = this.state.files[key];
      data.append('files[]', new Blob([file], { type: file.type }), file.name || 'file');
    });

    if (process.browser && window.yaCounter44700265) {
      data.append('client_id', window.yaCounter44700265.getClientID());
    }

    if (data.delete) {
      data.delete('files-field');
    }

    data.append('where', this.state.where);
    data.append('source', window.location.toString());
    data.append('version', 'v2');

    axios({
      url,
      method,
      data,
      headers
    })
      .then(({ data }) => {
        if (data.status === 'success') {
          if (fullscreen || action !== 'jobs') {
            if (window.Comagic) {
              window.Comagic.addOfflineRequest(
                {
                  name: state.name,
                  email: state.email,
                  phone: state.phone,
                  where: state.where,
                  message: `Задача: ${state.descr}, отдел: ${state.department}, бюджет: ${state.budget}`
                },
                function (o) {
                  console.log(o);
                }
              );
            }
            navigate('/order-success');
          } else {
            this.setState({
              sending: false,
              success: true
            });
          }

          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'sendOrder',
              eventCategory: 'new_formOrder',
              eventAction: 'new_send_form',
              GTM_param1: data.id
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  /**
   * Проверка полей на валидность
   * @returns {boolean}
   */
  isValid() {
    const { name, phone, email, descr, agree } = this.state;

    const lang = tokens[this.props.action];

    const error = [];

    if (!name || name.length < 2) {
      error.push({
        key: 'name',
        value: lang.required.name
      });
    }

    if (!phone) {
      error.push({
        key: 'phone',
        value: lang.required.phone
      });
    }

    if (phone && !testPhone(phone)) {
      error.push({
        key: 'phone',
        value: lang.error.phone
      });
    }

    if (!email) {
      error.push({
        key: 'email',
        value: lang.required.email
      });
    }

    if (!descr) {
      error.push({
        key: 'descr',
        value: lang.required.descr
      });
    }

    if (email && !/^.*@.*\..{2,}$/.test(email)) {
      error.push({
        key: 'email',
        value: lang.error.email
      });
    }

    if (!agree) {
      error.push({
        key: 'append',
        value: lang.required.append
      });
    }

    if (error.length === 0) {
      this.setState({
        error: {}
      });

      return true;
    } else {
      this.setState({
        error: error.reduce((list, { key, value }) => ({ ...list, [key]: value }), {})
      });

      const elem = this.block.querySelector(`[name="${error[0].key}"]`);

      if (elem) {
        elem.focus();
      }

      return false;
    }
  }

  onCloseSuccess() {
    this.setState({
      success: false
    });
  }

  onCloseError(name) {
    this.setState({
      error: {
        ...this.state.error,
        [name]: null
      }
    });
  }

  /**
   * Событие фокуса
   * @param name
   */
  onFocus(name) {
    this.setState({
      focus: name
    });
  }

  /**
   * Потеря фокуса
   */
  onFocusOut() {
    this.setState({
      focus: false
    });
  }

  render() {
    const { className, title, video, fullscreen, department, budget } = this.props;
    const { success, sending, error, focus } = this.state;
    const lang = tokens[this.props.action];

    return (
      <form
        className={classnames(this.cl({ success, sending, fullscreen }), className)}
        autoComplete={'false'}
        onSubmit={(e) => this.onSubmit(e)}
        ref={(node) => (this.block = node)}
        noValidate={'novalidate'}
      >
        <div className={this.cl('wrapper')}>
          <div className={this.cl('hello')}>
            <div className={this.cl('inner')}>
              <Heading className={this.cl('title')}>{title || lang.title}</Heading>
              <div className={this.cl('descr')}>
                {lang.contactsArr?.map((item, i) => (
                  <Contact item={item} key={'contact-' + i} cl={this.cl} />
                ))}
              </div>
              <div className={this.cl('contacts')}>
                <FooterLinks data={lang.footerLinks} cl={this.cl} />
              </div>
            </div>
            <video
              loop={true}
              className={this.cl('bg')}
              ref={(node) => (this.video = node)}
              playsInline={true}
              loading={'lazy'}
            >
              {video.map((item) => (
                <source key={item} src={'https://static.nimax.ru' + item} type={'video/' + item.split('.').pop()} />
              ))}
            </video>
          </div>
          <div className={this.cl('outer')}>
            <div className={this.cl('form')} onClick={(e) => this.onClickForm(e)}>
              <div className={this.cl('label')}>{lang.contacts}</div>
              <div className={this.cl('line', { columns: true })}>
                <div className={this.cl('section', { size: 's', error: Boolean(error.name), focus: focus === 'name' })}>
                  <Error className={this.cl('error')} onClose={() => this.onCloseError('name')}>
                    {error.name}
                  </Error>
                  <div className={this.cl('field')}>
                    <Input
                      name={'name'}
                      placeholder={lang.field.name}
                      value={this.state.name}
                      onChange={(...props) => this.onChange(...props)}
                      type={'text'}
                      tabIndex={1}
                      onFocus={() => this.onFocus('name')}
                      onFocusOut={() => this.onFocusOut()}
                    />
                  </div>
                </div>
                <div
                  className={this.cl('section', { size: 's', error: Boolean(error.phone), focus: focus === 'phone' })}
                >
                  <Error className={this.cl('error')} onClose={() => this.onCloseError('phone')}>
                    {error.phone}
                  </Error>
                  <div className={this.cl('field')}>
                    <Input
                      name={'phone'}
                      value={this.state.phone}
                      placeholder={lang.field.phone}
                      onChange={(...props) => this.onChange(...props)}
                      type={'tel'}
                      tabIndex={1}
                      onFocus={() => this.onFocus('phone')}
                      onFocusOut={() => this.onFocusOut()}
                    />
                  </div>
                </div>
              </div>
              <div className={this.cl('line', { space: 's' })}>
                <div
                  className={this.cl('section', {
                    size: 'stretch',
                    error: Boolean(error.email),
                    focus: focus === 'email'
                  })}
                >
                  <Error className={this.cl('error')} onClose={() => this.onCloseError('email')}>
                    {error.email}
                  </Error>
                  <div className={this.cl('field')}>
                    <Input
                      name={'email'}
                      value={this.state.email}
                      placeholder={lang.field.email}
                      onChange={(...props) => this.onChange(...props)}
                      type={'email'}
                      tabIndex={1}
                      onFocus={() => this.onFocus('email')}
                      onFocusOut={() => this.onFocusOut()}
                    />
                  </div>
                </div>
              </div>
              <div className={this.cl('line', { space: 'l' })}>
                <div className={this.cl('label')}>{lang.about}</div>
                {department && (
                  <div className={this.cl('line')}>
                    <div
                      className={this.cl('section', {
                        size: 'l',
                        full: true
                      })}
                    >
                      <Radio
                        name={'department'}
                        value={this.state.department}
                        options={lang.custom.department}
                        onChange={(value) => this.onChange('department', value)}
                      />
                    </div>
                  </div>
                )}
                {budget && (
                  <div className={this.cl('line')}>
                    <div
                      className={this.cl('section', {
                        size: 'l',
                        full: true
                      })}
                    >
                      <Radio
                        name={'budget'}
                        value={this.state.budget}
                        options={lang.custom.budget[budget]}
                        onChange={(value) => this.onChange('budget', value)}
                      />
                    </div>
                  </div>
                )}
                <div className={this.cl('line', { space: department || budget ? 's' : false })}>
                  <div
                    className={this.cl('section', {
                      size: 'stretch',
                      error: Boolean(error.descr),
                      focus: focus === 'descr'
                    })}
                  >
                    <Error className={this.cl('error')} onClose={() => this.onCloseError('descr')}>
                      {error.descr}
                    </Error>
                    <div className={this.cl('field')}>
                      <Input
                        name={'descr'}
                        placeholder={lang.field.descr}
                        value={this.state.descr}
                        onChange={(...props) => this.onChange(...props)}
                        multiLine={true}
                        tabIndex={1}
                        onFocus={() => this.onFocus('descr')}
                        onFocusOut={() => this.onFocusOut()}
                        attrs={{ rows: 3, style: { minHeight: '102px' } }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={this.cl('line_flex', { space: 's', reverce: 'y' })}>
                <Files
                  className={this.cl('files')}
                  name={'files-field'}
                  files={this.state.files}
                  onFilesChange={(...props) => this.onFilesChange(...props)}
                  onFilesError={(...props) => this.onFilesError(...props)}
                  filesRemoveOne={(...props) => this.filesRemoveOne(...props)}
                  setRef={(...props) => this.setRef(...props)}
                  text={lang.field.files}
                />
                <div className={this.cl('describe')}>
                  <ol type="1">
                    {lang.custom?.describe.map((item, i) => (
                      <li key={'descr-' + i} dangerouslySetInnerHTML={{ __html: item }} />
                    ))}
                  </ol>
                </div>
              </div>
              {/* <div className={this.cl('select_wrapper')}>
                <Select
                  className={this.cl('order')}
                  placeholder={'Откуда узнали про Nimax'}
                  options={[
                    'Реклама',
                    'Поиск',
                    'Рейтинги',
                    'СМИ',
                    'Соцсети',
                    'Блог',
                    'Выступление конференция',
                    'Рекомендация',
                    'Давно знаю',
                    'Другое'
                  ]}
                  onChange={(value) => this.onChange('where', value)}
                  name={'where'}
                />
              </div> */}
              <div className={classnames(this.cl('line_flex'))}>
                <Button className={this.cl('submit')} type={'submit'} size={'l'} onClick={() => {}} tabIndex={1}>
                  {lang.submit}
                </Button>
                <div className={this.cl('agree')} dangerouslySetInnerHTML={{ __html: lang.field.agree }} />
              </div>
            </div>
          </div>
        </div>
        <Loader className={this.cl('loader')} />
        {this.props.action === 'jobs' && (
          <FormStatus className={this.cl('form-status')} onClose={() => this.onCloseSuccess()} />
        )}
      </form>
    );
  }
}

export default Order;
