import React, { useEffect, useState } from 'react';
import { clearNbsp } from '../../../../utils/clearNbsp';
import { Image } from 'elements';
import icon from './icon.svg';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import Wrapper from './Wrapper';

const Service = ({ service, cl }) => {
  const [active, setActive] = useState(false);
  const [pageType, setPageType] = useState('desktop');
  const { width } = useWindowSize();
  const name = clearNbsp(service.name);
  const text = clearNbsp(service.text);

  const clickHandler = (e) => {
    if (width <= 412) {
      setActive(true);
    }
  };

  useEffect(() => {
    width <= 412 ? setPageType('mobile') : setPageType('desktop');
  }, [width]);

  return (
    // <a
    //   className={active ? cl('item active') : cl('item')}
    //   onClick={clickHandler}
    //   href={pageType === 'desktop' ? service.link : undefined}
    // >
    <Wrapper active={active} cl={cl} clickHandler={clickHandler} link={service.link} pageType={pageType}>
      <div className={cl('item_name')}>{name}</div>
      <div className={cl('item_text')}>
        {text}
        {pageType === 'mobile' && service.link ? <a href={service.link}> Read more</a> : ''}
      </div>
      <div className={cl('item_image')}>
        <Image
          src={pageType === 'mobile' ? service.images.mobile : service.images.desc}
          className={cl('item_image_inner')}
        />
      </div>
      {!active && (
        <div className={cl('item_icon')}>
          <img src={icon} />
        </div>
      )}
    </Wrapper>
    // </a>
  );
};

export default Service;
