import React, { Component } from 'react';
import { Match } from '@reach/router';
import Headroom from 'headroom.js';

import Button from 'elements/Button/Button';
import Icon from 'elements/Icon/Icon';
import { classnames, classname, back } from 'utils';

import './Header.css';
import tokens from './Header.tokens';

export class Header extends Component {
  cl = classname('header');

  static defaultProps = {
    theme: 'dark'
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!process.browser) return;

    this.headroom = new Headroom(this.block, {
      classes: {
        unpinned: 'header_unpinned'
      },
      offset: 100,
      tolerance: {
        up: 5,
        down: 0
      }
    });
    this.headroom.init();
  }

  render() {
    const { className, theme, headerColor } = this.props;

    const classes = classnames(
      className,
      this.cl({ theme }),
      headerColor === 'light' ? 'header_color_light' : 'header_color_dark'
    );
    return (
      <header className={classes} ref={(node) => (this.block = node)}>
        {this.props.logo !== false && (
          <a href="/" className={this.cl('logo')}>
            <img
              src={`/logo/${headerColor === 'light' ? 'logo_dark.svg' : 'logo_light.svg'}`}
              alt="Digital-agency Nimax"
            />
          </a>
        )}
        {this.props.type !== 'light' && (
          <nav className={this.cl('group', { nav: true })}>
            {tokens.map((item) => {
              return !item.children ? (
                <a href={item.link} key={item.name} className={classnames(this.cl('item'), this.cl('link'))}>
                  {item.name}
                </a>
              ) : (
                <div className={this.cl('item')} key={item.name}>
                  <div className={this.cl('link')}>
                    <div className={this.cl('name')}>
                      {item.name}
                      <Icon name={'arrow'} className={this.cl('arrow')} />
                    </div>
                  </div>
                  <div className={this.cl('children')}>
                    {item.children.map((child) => (
                      <a href={child.link} key={child.name}>
                        {child.name}
                      </a>
                    ))}
                  </div>
                </div>
              );
            })}
          </nav>
        )}
        <Match path={'*'}>
          {({ match }) => {
            const isOrder = ['order', 'menu'].includes(match['*']);
            const isOrderSuccess = ['order-success'].includes(match['*']);

            if (isOrder) {
              return (
                <>
                  <button type={'button'} className={this.cl('close')} onClick={() => back()}>
                    <Icon name={'cancel'} />
                  </button>
                </>
              );
            } else if (isOrderSuccess) {
              return (
                <>
                  <button type={'button'} className={this.cl('close')} onClick={() => back()}>
                    <Icon name={'cancel'} />
                  </button>
                </>
              );
            } else {
              return (
                <>
                  <div className={this.cl('group', { contacts: true })}>
                    <div className={this.cl('item')}>
                      <Button url={'/order/'}>Request</Button>
                    </div>
                  </div>
                  <a href="/menu/" className={classnames(this.cl('burger'), this.cl('link'))}>
                    <Icon name={'burger'} />
                  </a>
                </>
              );
            }
          }}
        </Match>
      </header>
    );
  }
}
