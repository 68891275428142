import React, { Component } from 'react';
import { classname, classnames } from 'utils';
import { Hat } from 'components/Hat/Hat';
import { Feed, Seo, SeoHidden } from 'components';
import { Helmet } from 'react-helmet';

import './Contacts.css';

const SCHEMA = {
  // '@context': 'https://schema.org',
  // '@type': 'Organization',
  // url: 'https://www.nimax.ru',
  // logo: 'https://www.nimax.ru/logo/logo_light.svg',
  // address: {
  //   '@type': 'PostalAddress',
  //   addressCountry: 'Россия',
  //   addressLocality: 'Санкт-Петербург',
  //   postalCode: '190005',
  //   streetAddress: 'Московский проспект, д. 55, 3 этаж'
  // },
  // email: 'hello@nimax.ru',
  // name: 'Nimax',
  // telephone: '+7 (812) 604-83-56',
  // image: [
  //   'https://www.nimax.ru/img/schema/image0.jpeg',
  //   'https://www.nimax.ru/img/schema/image1.jpeg',
  //   'https://www.nimax.ru/img/schema/image2.jpeg'
  // ]
};

class Contacts extends Component {
  cl = classname('contancts');

  render() {
    const { list, feed, className } = this.props;
    return (
      <>
        <Helmet>
          <script type="application/ld+json">{`${JSON.stringify(SCHEMA)}`}</script>
        </Helmet>
        <div className={classnames(this.cl(), className)}>
          <Seo title={'Nimax contacts'} description={' Nimax agency address, phone number and e-mail'} />
          <Hat title={'We are here <br/>to&nbsp;talk'} office={true} />
          {/* Не работает FaceList тк нет людей */}
          {/* <Feed list={list} feed={feed} /> */}
          <SeoHidden>
            <h1>Contacts</h1>
          </SeoHidden>
        </div>
      </>
    );
  }
}

export default Contacts;
