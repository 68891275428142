import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'elements';
import { classname, classnames } from 'utils';
import './Dropdown.css';

/**
 * Выпадающий текст
 */
class Dropdown extends Component {
  cl = classname('dropdown');

  state = {
    isShow: false
  };

  static propTypes = {
    /** Дополнительный класс */
    className: PropTypes.string,
    /** Содержимое комментария */
    children: PropTypes.node.isRequired,
  };

  onClick(e) {
    this.setState({
      isShow: !this.state.isShow
    });

    e.preventDefault();
  }

  render() {
    const { className, children } = this.props;
    const { isShow } = this.state;

    return (
      <div className={classnames(this.cl(), className)}>
        <button type={'button'} className={this.cl('button')} onClick={e => this.onClick(e)}>
          {isShow ? "See less" : "See more"}
          <div
            className={isShow ? this.cl('inverted-icon') : this.cl('icon')}>
            <Icon name={'arrow'} />
          </div>
        </button>
          <div className={isShow ? this.cl('border') : ''}>
            {isShow && (
              <>
                {React.Children.map(children, (item, index) => (isShow || index < 2 ? item : null))}
              </>
            )}
            </div>
      </div>
    );
  }
}
export default Dropdown;
