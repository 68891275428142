import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Block from './Footnotes.style';

/**
 * Сноски
 */
class Footnotes extends PureComponent {
  static propTypes = {
    left: PropTypes.node,
    right: PropTypes.node
  };

  render() {
    const { children, left, right } = this.props;

    return (
      <Block>
        {left && <Block.Append align="left">{left}</Block.Append>}
        {children}
        {right && <Block.Append align="right">{right}</Block.Append>}
      </Block>
    );
  }
}

export default Footnotes;
