import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classnames, classname } from 'utils';
import { Card, FaceList } from 'components';
import { Icon, Button, Recording } from 'elements';
import './CardMore.css';

/**
 * Карточка смотреть еще
 */
class CardMore extends Component {
  cl = classname('card-more');

  static propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    url: PropTypes.string
  };

  render() {
    const { className, title, label, url, type, ...props } = this.props;

    const classes = classnames(className, this.cl());
    return (
      <Card className={classes} title={title} url={url} type={''} {...props}>
        <div className={this.cl('footer')}>
          {label && <div className={this.cl('label')}>{label}</div>}
          <Button className={this.cl('button')} size={'m'}>
            Go To
          </Button>
        </div>
      </Card>
    );
  }
}

export default CardMore;
