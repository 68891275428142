import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Button, Icon } from 'elements';
import { Hat } from 'components/Hat/Hat';
import { Seo, Feed, SeoHidden, ToggleList } from 'components';
import { classname, classnames } from 'utils';
import './Projects.css';

const tagsImport = require('../../../content/categories/tags.json');
const projectsMeta = require('../../../projects-meta.json');
const tags = tagsImport['tags'];

/**
 * Страницы портфолио
 */

class Projects extends Component {
  cl = classname('projects');

  constructor(props) {
    super(props);

    this.state = {
      tags: []
    };
  }

  onClickTag = (value) => {
    if (this.state.tags.includes(value)) {
      this.setState({
        tags: this.state.tags.filter((item) => item !== value)
      });
    } else {
      this.setState({
        tags: [...this.state.tags, value]
      });
    }
  };

  onClickReset = () => {
    this.setState({
      tags: []
    });
  };

  render() {
    const { id, category, feed, className, list } = this.props;

    let industries = tags['projects'];

    if (category !== 'projects') {
      industries = [];

      tags['projects'].forEach((item) => {
        list.forEach((el) => {
          if (
            feed[`projects/${el}`] &&
            ~feed[`projects/${el}`].tags?.indexOf(tags[category][0]) &&
            ~feed[`projects/${el}`].tags?.indexOf(item)
          ) {
            if (!~industries.indexOf(item)) {
              industries.push(item);
            }
          }
        });
      });
    }

    if (category === 'performance') {
      ['FMCG', 'недвижимость', 'ecommerce'].forEach((element) => {
        industries.unshift(...industries.splice(industries.indexOf(element), 1));
      });
    }

    return (
      <div className={classnames(this.cl(), className)}>
        <Seo
          title={projectsMeta[category]['seo_title'] || projectsMeta[category]['title']}
          description={projectsMeta[category]['description']}
        />
        {projectsMeta[category]['seo_h1'] ? (
          <>
            <Hat title={projectsMeta[category]['title']} pageType={id} tags={tags['projects']} />
            <SeoHidden>
              <h1>{projectsMeta[category]['seo_h1']}</h1>
            </SeoHidden>
          </>
        ) : (
          <Hat title={projectsMeta[category]['title']} pageType={id} as={'h1'} tags={tags['projects']} />
        )}

        {/* <div className={this.cl('filter')}>
          <ToggleList
            items={industries.map((tag) => (
              <div key={tag} className={this.cl('filter-button', { active: this.state.tags.includes(tag) })}>
                <Button
                  color={'gray_50'}
                  size={'s'}
                  weight={'normal'}
                  key={tag}
                  type={'button'}
                  onClick={() => this.onClickTag(tag)}
                >
                  {tag}
                </Button>
              </div>
            ))}
            minItems={industries.length > 7 ? 7 : false}
          />
          <button
            type={'button'}
            className={this.cl('reset-button', { picked: !!this.state.tags.length })}
            onClick={() => this.onClickReset(this)}
          >
            <div className={this.cl('reset-button-wrapper')}>
              <span>Сбросить фильтр</span>
              <Icon className={this.cl('reset-button-icon')} name={'cancel'} size={'fit'} />
            </div>
          </button>
        </div> */}

        <Feed
          list={list.map((item) => {
            if (/\//.test(item)) {
              return item;
            } else {
              return [id, item].join('/');
            }
          })}
          category={category !== 'projects' ? tags[category][0] : false}
          tag={this.state.tags}
        />
      </div>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        feed: allMdx {
          ...CardProject
        }
      }
    `}
    render={({ feed: { edges: feed } }) => {
      feed = feed.reduce(
        (list, { node }) => ({ ...list, [node.fields.id]: { ...node.frontmatter, ...node.fields } }),
        {}
      );

      return <Projects {...props} feed={feed} />;
    }}
  />
);
