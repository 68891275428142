import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { classname } from 'utils';
import Button from './components/Button';
import Case from './components/Case';
import './main-cases.css';

const MainCases = ({ feed = [], link }) => {
  const cl = classname('main_cases');
  const [showMore, setShowMore] = useState(feed.length > 4);

  const feedHandler = (item, index) => {
    if (index < 4 || !showMore) {
      return <Case item={item} key={`media-case-${index}`} cl={cl} />;
    }
  };

  return (
    <div className={cl('wrapper')}>
      <div className={cl('inner')}>{feed.map(feedHandler)}</div>
      {showMore ? (
        <Button text={'больше кейсов'} cl={cl} setShow={setShowMore} />
      ) : (
        <Button text={'все кейсы'} cl={cl} setShow={setShowMore} link={link} />
      )}
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      {
        allMdx(filter: { fields: { id: { regex: "/^projects//" } } }) {
          edges {
            node {
              frontmatter {
                soon
                redirect
                nnx_image
                nnx_video
                nnx_description
                nnx_description_1
                nnx_title
                nnx_tags
                nnx_theme
              }
              fields {
                id
                uri
                category
              }
            }
          }
        }
      }
    `}
    render={({ allMdx: { edges = [] } }) => {
      const { feed = [], link } = props;
      const filterHandler = (project) => feed.includes(project.node.fields.id);

      const hash = edges
        .filter(filterHandler)
        .reduce((list, { node }) => ({ ...list, [node.fields.id]: { ...node.frontmatter, ...node.fields } }), {});

      const projects = feed.map((item) => hash[item]);

      return <MainCases feed={projects} link={link} />;
    }}
  />
);
