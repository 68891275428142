import React from 'react';
import icon from './shape.svg';

const More = ({ cl, link }) => {
  return (
    <div className={cl('more-wrapper')}>
      <a className={cl('more')} href={link}>
        <img src={icon} className={cl('more-icon')} />
        All cases
      </a>
    </div>
  );
};

export default More;
