import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            services {
              title
              url
            }
          }
        }
      }
    `}
    render={(res) => children(res.site.siteMetadata.services)}
  />
);
